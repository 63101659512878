Vue.directive('selectpicker', {
    twoWay: true,
    deep: true,
    bind: function() {
        $(this.el).selectpicker().on("change", function(e) {
            this.set($(this.el).val());
        }.bind(this));
    },
    update: function () {
        $(this.el).selectpicker('refresh').trigger("change");
    },
    unbind: function () {
        $(this.el).off().selectpicker('destroy');
    }
});

Vue.directive('select2', {
    twoWay: true,
    deep: true,
    bind: function() {
        $(this.el).select2().on("change", function(e) {
            this.set($(this.el).val());
        }.bind(this));
    },
    update: function () {
        $(this.el).trigger("change");
    },
    unbind: function () {
        $(this.el).off().select2('destroy');
    }
});